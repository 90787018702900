import * as React from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "assets/icons/menu";
import CircleButton from "components/forms/circle-button";
import CloseIcon from "assets/icons/close";
import { menus } from "../config";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import { navigate } from "gatsby";

export default function SideBar() {
  const params = new URLSearchParams(
    typeof window === null ? "" : location.search
  );
  const tag = params?.get("tag") ?? "";

  const theme = useTheme();
  const useStyles = makeStyles({
    paper: {
      background: theme.palette.custom.appbar,
      backdropFilter: "blur(12px)saturate(200%)",
      webkitBackdropFilter: "blur(12px)saturate(200%)",
      borderLeft: theme.palette.mode === "light" ? "none" : "1px solid",
      borderColor:
        theme.palette.mode === "light" ? "none" : theme.palette.divider,
    },
  });
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const list = () => (
    <Box
      sx={{ width: 250 }}
      onClick={() => setOpen(false)}
      onKeyDown={() => setOpen(false)}
    >
      <List>
        {/* <ListItem button onClick={() => setOpen(false)}>
          <ListItemIcon>
            <CloseIcon fontSize="small" sx={{ color: "text.disabled" }} />
          </ListItemIcon>
          <ListItemText primary="Close Menu" sx={{ color: "text.secondary" }} />
        </ListItem>

        <Grid item xs={12}>
          <Divider />
        </Grid> */}

        {/* <ListItem button onClick={() => setOpen(false)}>
          <ListItemIcon>
            <CloseIcon sx={{ color: "text.disabled", fontSize: 12 }} />
          </ListItemIcon>
        </ListItem> */}

        {menus.map((menu, index) => (
          <ListItem key={menu.label} button onClick={() => navigate(menu.to)}>
            {index === 0 && (
              <ListItemIcon
                onClick={(e) => {
                  e.stopPropagation();
                  setOpen(false);
                }}
              >
                <CloseIcon sx={{ color: "text.disabled", fontSize: 12 }} />
              </ListItemIcon>
            )}
            <ListItemText
              primary={menu.label}
              sx={{
                color: menu.label === tag ? "primary.main" : "text.secondary",
                textAlign: "right",
              }}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <div>
      <CircleButton
        variant="no-border"
        Icon={MenuIcon}
        onClick={() => setOpen(true)}
      />
      <SwipeableDrawer
        anchor="right"
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        classes={{ paper: classes.paper }}
      >
        {list()}
      </SwipeableDrawer>
    </div>
  );
}
