import * as React from "react";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import CircleButton from "components/forms/circle-button";
import SearchIcon from "assets/icons/search";
import NightIcon from "assets/icons/night";
import DayIcon from "assets/icons/day";
import useWindowPosition from "hooks/use-window-position";
import NavLink from "components/typography/nav-link";
import { Link } from "gatsby-theme-material-ui";
import { navigate } from "gatsby";
import { useTheme } from "@mui/material/styles";
import { ColorModeContext } from "gatsby-theme-material-ui-top-layout/components/top-layout";
import SideBar from "./side-bar";
import useMediaQuery from "@mui/material/useMediaQuery";
import { menus } from "../config";
import { getImage } from "utils/image";

export default function AppBar() {
  // const params = new URLSearchParams(location.search);
  // const tag = params.get("tag") ?? "";

  const position: number = useWindowPosition();
  const transparentAppBar: boolean = position < 10;
  const theme = useTheme();
  const colorMode = React.useContext(ColorModeContext);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <MuiAppBar
      id="app-bar"
      color="default"
      sx={{
        transition: "padding 0.3s ease-out",
        background: transparentAppBar ? "none" : theme.palette.custom.appbar,
        border: "none",
        borderBottom: transparentAppBar ? "none" : "1px solid",
        borderColor: theme.palette.custom.appbarDivider,
        backdropFilter: transparentAppBar ? "none" : "blur(12px)saturate(200%)",
        webkitBackdropFilter: transparentAppBar
          ? "none"
          : "blur(12px)saturate(200%)",
        boxShadow: transparentAppBar
          ? "none"
          : "0px 8px 24px 2px rgba(0,0,0,0.1)",
        pt: transparentAppBar ? 4 : 0,
        px: isMobile ? 0 : 6,
        zIndex: 1200,
      }}
    >
      <Toolbar>
        <Grid container justifyContent="space-between" alignItems="center">
          <Link to="/" textTransform={undefined}>
            <img
              placeholder="blurred"
              src={getImage(
                theme.palette.mode === "dark"
                  ? "/assets/images/logo-dark.png"
                  : "/assets/images/logo.png"
              )}
              alt="logo"
              height={50}
              width={124}
            />
          </Link>
          {isDesktop && (
            <Stack direction="row" spacing={2}>
              {menus.map((menu) => (
                <NavLink
                  label={menu.label}
                  to={menu.to}
                  // isActive={menu.label === tag}
                />
              ))}
            </Stack>
          )}

          <Stack
            direction="row"
            spacing={0}
            alignItems="center"
            justifyContent="flex-end"
            sx={{ width: 124 }}
          >
            <CircleButton
              variant="no-border"
              tooltip="Search"
              Icon={SearchIcon}
              onClick={() => {
                navigate("/search");
              }}
            />
            <CircleButton
              variant="no-border"
              tooltip={`Switch to ${
                theme.palette.mode === "dark" ? "Light" : "Dark"
              } Theme`}
              Icon={theme.palette.mode === "dark" ? NightIcon : DayIcon}
              onClick={colorMode.toggleColorMode}
            />
            {isMobile && <SideBar />}
          </Stack>
        </Grid>
      </Toolbar>
    </MuiAppBar>
  );
}
