export interface Menu {
  label: string;
  to: string;
}

export const menus: Menu[] = [
  { label: "iOS Development", to: "/articles?tag=iOS Development" },
  { label: "Android Development", to: "/articles?tag=Android Development" },
  { label: "Cross Platform", to: "/articles?tag=Cross Platform" },
  { label: "Web Development", to: "/articles?tag=Web Development" },
  { label: "Trading", to: "/articles?tag=Trading" },
  //  {label: "Others", to: "/articles?tag=Others"},
  { label: "About", to: "https://alfin.dev" },
];
