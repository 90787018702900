import red from "@mui/material/colors/red";
import { createTheme } from "@mui/material";
import MuliRegular from "../../assets/fonts/Muli-Regular.woff2";
import OperatorMonoMedium from "../../assets/fonts/OperatorMono-Medium.woff2";
import OperatorMonoMediumItalic from "../../assets/fonts/OperatorMono-MediumItalic.woff2";

declare module "@mui/material/styles" {
  interface Palette {
    custom: {
      appbar: string;
      appbarDivider: string;
      tagsBackground: string;
      tagBackgroundHovered: string;
      tagBackground: string;
      tagForeground: string;
    };
  }

  interface PaletteOptions {
    custom?: {
      appbar?: string;
      appbarDivider?: string;
      tagsBackground: string;
      tagBackgroundHovered: string;
      tagBackground?: string;
      tagForeground?: string;
    };
  }
}

const lightTheme = createTheme({
  palette: {
    mode: "light",
    custom: {
      appbar: "rgba(255,255,255,0.85)",
      appbarDivider: "transparent",
      tagsBackground: "#000",
      tagBackgroundHovered: "rgba(0,0,0,0.15)",
      tagBackground: "#E5E5E5",
      tagForeground: "rgba(0,0,0,0.25)",
    },
    primary: {
      main: "#f2aa00",
      contrastText: "#805400",
    },
    secondary: {
      main: "rgba(0,0,0,0.5)",
      contrastText: "rgba(0,0,0,1)",
    },
    error: {
      main: "#FF3F3F",
    },
    background: {
      default: "#FFF",
      paper: "#FFF",
    },
    text: {
      primary: "#24283D",
      secondary: "#7F7F7F",
      disabled: "rgba(0,0,0,0.25)",
    },
    action: {
      disabledBackground: "rgba(185,185,185,0.2)",
      disabled: "rgba(185,185,185,1)",
    },
    divider: "rgba(0,0,0,0.2)",
  },
  typography: {
    fontFamily: 'Muli, "Helvetica", "Arial", sans-serif',
    h4: {
      fontWeight: "bold",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Muli';
          src: local('Muli'), local('Muli-Regular'), url(${MuliRegular}) format('woff2');
        }

        @font-face {
          font-family: 'OperatorMono-Medium';
          src: local('OperatorMono-Medium'), local('OperatorMono-Medium'), local('Operator Mono'), url(${OperatorMonoMedium}) format('woff2');
          font-weight: 500;
        }

        @font-face {
          font-family: 'OperatorMono-MediumItalic';
          src: local('OperatorMono-MediumItalic'), local('OperatorMono-MediumItalic'), local('OperatorMono-Medium'), local('Operator Mono'), url(${OperatorMonoMediumItalic}) format('woff2');
          font-weight: 500;
        }
      `,
    },
  },
});

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    custom: {
      appbar: "rgba(36,40,61,0.75)",
      appbarDivider: "rgba(255,255,255,0.25)",
      tagsBackground: "#f00",
      tagBackgroundHovered: "#484d6b",
      tagBackground: "#363B56",
      tagForeground: "rgba(255,255,255,0.5)",
    },
    primary: {
      main: "#FFC700",
      contrastText: "#805400",
    },
    secondary: {
      main: "rgba(255,255,255,0.75)",
      contrastText: "rgba(255,255,255,1)",
    },
    error: {
      main: "#FF3F3F",
    },
    background: {
      default: "#24283D",
      paper: "#24283D",
    },
    text: {
      primary: "#FFF",
      secondary: "#C8C9CE",
      disabled: "rgba(255,255,255,0.5)",
    },
    action: {
      disabledBackground: "#32364F",
      disabled: "#636679",
    },
    divider: "rgba(255,255,255,0.25)",
  },
  typography: {
    fontFamily: 'Muli, "Helvetica", "Arial", sans-serif',
    h4: {
      fontWeight: "bold",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Muli';
          src: local('Muli'), local('Muli-Regular'), url(${MuliRegular}) format('woff2');
        }

        @font-face {
          font-family: 'Operator Mono';
          src: local('OperatorMono-Medium'), local('OperatorMono-Medium'), local('Operator Mono') url(${OperatorMonoMedium}) format('woff2');
        }

        @font-face {
          font-family: 'OperatorMono-MediumItalic';
          src: local('OperatorMono-MediumItalic'), local('OperatorMono-MediumItalic'), local('OperatorMono-Medium'), local('Operator Mono') url(${OperatorMonoMediumItalic}) format('woff2');
        }
      `,
    },
  },
});

export { lightTheme, darkTheme };
