import React from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

function Icon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M21 1.5C21.3978 1.5 21.7794 1.65804 22.0607 1.93934C22.342 2.22064 22.5 2.60218 22.5 3V21C22.5 21.3978 22.342 21.7794 22.0607 22.0607C21.7794 22.342 21.3978 22.5 21 22.5H3C2.60218 22.5 2.22064 22.342 1.93934 22.0607C1.65804 21.7794 1.5 21.3978 1.5 21V3C1.5 2.60218 1.65804 2.22064 1.93934 1.93934C2.22064 1.65804 2.60218 1.5 3 1.5H21ZM3 0C2.20435 0 1.44129 0.316071 0.87868 0.87868C0.316071 1.44129 0 2.20435 0 3L0 21C0 21.7956 0.316071 22.5587 0.87868 23.1213C1.44129 23.6839 2.20435 24 3 24H21C21.7956 24 22.5587 23.6839 23.1213 23.1213C23.6839 22.5587 24 21.7956 24 21V3C24 2.20435 23.6839 1.44129 23.1213 0.87868C22.5587 0.316071 21.7956 0 21 0L3 0Z" />
      <path d="M8.25 18C8.25 18.5967 8.01295 19.169 7.59099 19.591C7.16903 20.0129 6.59674 20.25 6 20.25C5.40326 20.25 4.83097 20.0129 4.40901 19.591C3.98705 19.169 3.75 18.5967 3.75 18C3.75 17.4033 3.98705 16.831 4.40901 16.409C4.83097 15.9871 5.40326 15.75 6 15.75C6.59674 15.75 7.16903 15.9871 7.59099 16.409C8.01295 16.831 8.25 17.4033 8.25 18ZM3.75 5.25C3.75 4.85218 3.90804 4.47064 4.18934 4.18934C4.47064 3.90804 4.85218 3.75 5.25 3.75C13.5345 3.75 20.25 10.4655 20.25 18.75C20.25 19.1478 20.092 19.5294 19.8107 19.8107C19.5294 20.092 19.1478 20.25 18.75 20.25C18.3522 20.25 17.9706 20.092 17.6893 19.8107C17.408 19.5294 17.25 19.1478 17.25 18.75C17.25 15.5674 15.9857 12.5152 13.7353 10.2647C11.4848 8.01428 8.4326 6.75 5.25 6.75C4.85218 6.75 4.47064 6.59196 4.18934 6.31066C3.90804 6.02936 3.75 5.64782 3.75 5.25ZM3.75 11.25C3.75 10.8522 3.90804 10.4706 4.18934 10.1893C4.47064 9.90804 4.85218 9.75 5.25 9.75C7.63695 9.75 9.92613 10.6982 11.614 12.386C13.3018 14.0739 14.25 16.3631 14.25 18.75C14.25 19.1478 14.092 19.5294 13.8107 19.8107C13.5294 20.092 13.1478 20.25 12.75 20.25C12.3522 20.25 11.9706 20.092 11.6893 19.8107C11.408 19.5294 11.25 19.1478 11.25 18.75C11.25 17.1587 10.6179 15.6326 9.49264 14.5074C8.36742 13.3821 6.8413 12.75 5.25 12.75C4.85218 12.75 4.47064 12.592 4.18934 12.3107C3.90804 12.0294 3.75 11.6478 3.75 11.25Z" />
    </SvgIcon>
  );
}

export default Icon;
