import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function Quotes(props: {
  by?: string;
  children: React.ReactNode;
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box sx={{ px: isMobile ? 2 : 6, my: isMobile ? 2 : 4 }}>
      <Typography
        variant="h6"
        color="secondary"
        sx={{
          fontSize: 16,
          textIndent: "-0.45em",
          "&::before": {
            content: "open-quote",
          },
          "&::after": {
            content: "close-quote",
          },
        }}
      >
        {props.children}
      </Typography>

      {props?.by !== null && (
        <Typography
          variant="subtitle1"
          sx={{ color: theme.palette.text.disabled }}
        >
          {props?.by}
        </Typography>
      )}
    </Box>
  );
}
