import React from "react";
import { styled } from "@mui/system";
import { alpha } from "@mui/material/styles";

interface Props {
  children: React.ReactNode;

  [x: string]: any;
}

const MuiLink = styled("a")(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: "none",
  borderBottom: "1.5px dashed",
  borderColor: alpha(theme.palette.primary.main, 0.5),
  background: alpha(theme.palette.primary.main, 0.1),
  wordWrap: "break-word",
  "&:hover": {
    borderColor: alpha(theme.palette.primary.main, 1),
  },
  "&:visited": {
    color: theme.palette.primary.main,
  },
}));

export default function Link({ children, ...rest }: Props) {
  return (
    <MuiLink target="_blank" {...rest}>
      {children}
    </MuiLink>
  );
}
