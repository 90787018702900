import * as React from "react";
import { styled } from "@mui/material/styles";
import MuiTooltip, {
  TooltipProps,
  tooltipClasses,
} from "@mui/material/Tooltip";

const Tooltip = styled(({ className, ...props }: TooltipProps) => (
  <MuiTooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    "&::before": {
      border: "0.25px solid",
      borderColor:
        theme.palette.mode === "light"
          ? "transparent"
          : "rgba(255,255,255,0.25)",
      backgroundColor: "#363B56",
    },
  },
  [`& .${tooltipClasses.tooltip}`]: {
    border: "0.25px solid",
    borderColor:
      theme.palette.mode === "light" ? "transparent" : "rgba(255,255,255,0.25)",
    backgroundColor: "#363B56",
    color: "#FFF",
    borderRadius: "8px",
  },
}));

export default Tooltip;
