import React from "react";
import { Box, Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import RssIcon from "assets/icons/rss";
import { Link } from "gatsby-theme-material-ui";

export default () => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "block",
        color: "text.secondary",
        bgcolor: theme.palette.custom.tagBackground,
        textAlign: "center",
        p: 2,
      }}
    >
      All rights reserved © Alfin Syahruddin · 2019
      <br />
      <Link target="_blank" to="/rss.xml" textTransform={undefined}>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ mt: 1 }}
        >
          <RssIcon sx={{ mr: 1, width: 18, height: 18 }} />
          <span>RSS Feed</span>
        </Grid>
      </Link>
    </Box>
  );
};
