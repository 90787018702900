import React from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

function Icon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M14.8173 11.9966L23.409 3.42487C23.7852 3.04863 23.9966 2.53833 23.9966 2.00624C23.9966 1.47416 23.7852 0.963859 23.409 0.587616C23.0327 0.211372 22.5224 0 21.9903 0C21.4583 0 20.948 0.211372 20.5717 0.587616L12 9.17931L3.42828 0.587616C3.05204 0.211372 2.54174 -3.96437e-09 2.00965 0C1.47757 3.96437e-09 0.967269 0.211372 0.591025 0.587616C0.214782 0.963859 0.00340991 1.47416 0.00340991 2.00624C0.0034099 2.53833 0.214782 3.04863 0.591025 3.42487L9.18272 11.9966L0.591025 20.5683C0.40375 20.7541 0.255105 20.975 0.153666 21.2185C0.0522265 21.462 0 21.7232 0 21.9869C0 22.2507 0.0522265 22.5119 0.153666 22.7553C0.255105 22.9988 0.40375 23.2198 0.591025 23.4056C0.776772 23.5928 0.99776 23.7415 1.24124 23.8429C1.48473 23.9444 1.74589 23.9966 2.00965 23.9966C2.27342 23.9966 2.53458 23.9444 2.77807 23.8429C3.02155 23.7415 3.24254 23.5928 3.42828 23.4056L12 14.8139L20.5717 23.4056C20.7575 23.5928 20.9785 23.7415 21.2219 23.8429C21.4654 23.9444 21.7266 23.9966 21.9903 23.9966C22.2541 23.9966 22.5153 23.9444 22.7588 23.8429C23.0022 23.7415 23.2232 23.5928 23.409 23.4056C23.5962 23.2198 23.7449 22.9988 23.8463 22.7553C23.9478 22.5119 24 22.2507 24 21.9869C24 21.7232 23.9478 21.462 23.8463 21.2185C23.7449 20.975 23.5962 20.7541 23.409 20.5683L14.8173 11.9966Z" />
    </SvgIcon>
  );
}

export default Icon;
