import Tooltip from "components/tooltip";
import IconButton from "@mui/material/IconButton";
import React from "react";
import Zoom from "@mui/material/Zoom";

interface Props {
  Icon: React.FC;
  color?: string | null;
  onClick?: () => void;
  tooltip?: string;
  variant?: "bordered" | "no-border";
  iconProps?: any;

  [key: string]: any;
}

export default function CircleButton({
  Icon,
  onClick,
  tooltip = "App Button",
  color = null,
  variant = "bordered",
  iconProps = {},
  ...rest
}: Props) {
  return (
    <Tooltip arrow title={tooltip} TransitionComponent={Zoom}>
      <IconButton
        onClick={onClick}
        sx={{
          border: "0.25px solid",
          borderColor:
            variant === "bordered"
              ? !rest?.disabled && color
                ? color
                : "divider"
              : "transparent",
          p: 1.25,
          color: color ?? "text.secondary",
        }}
        aria-label={tooltip}
        {...rest}
      >
        <Icon {...iconProps} />
      </IconButton>
    </Tooltip>
  );
}
