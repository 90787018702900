import { PrismTheme } from "prism-react-renderer";

const theme: PrismTheme = {
    plain: {
      color: "#d6deeb",
      backgroundColor: "rgb(1, 22, 38)",
      fontFamily: '"OperatorMono-Medium", "Courier New", Courier, monospace',
    },
    styles: [{
      types: ["changed"],
      style: {
        color: "rgb(162, 191, 252)",
        fontFamily: 'OperatorMono-MediumItalic, OperatorMono-Medium, monospace',
      }
    }, {
      types: ["deleted"],
      style: {
        color: "rgba(239, 83, 80, 0.56)",
        fontFamily: 'OperatorMono-MediumItalic, OperatorMono-Medium, monospace',
      }
    }, {
      types: ["inserted", "attr-name"],
      style: {
        color: "rgb(173, 219, 103)",
        fontFamily: 'OperatorMono-MediumItalic, OperatorMono-Medium, monospace',
      }
    }, {
      types: ["comment"],
      style: {
        color: "rgb(99, 119, 119)",
        fontFamily: 'OperatorMono-MediumItalic, OperatorMono-Medium, monospace',
      }
    }, {
      types: ["string", "url"],
      style: {
        color: "rgb(173, 219, 103)",
        fontFamily: 'OperatorMono-Medium, monospace',
      }
    }, {
      types: ["variable"],
      style: {
        color: "rgb(214, 222, 235)",
      }
    }, {
      types: ["number"],
      style: {
        color: "rgb(247, 140, 108)"
      }
    }, {
      types: ["builtin", "char", "constant", "function"],
      style: {
        color: "rgb(130, 170, 255)",
      }
    }, {
      // This was manually added after the auto-generation
      // so that punctuations are not italicised
      types: ["punctuation"],
      style: {
        color: "rgb(199, 146, 234)",
      }
    }, {
      types: ["selector", "doctype"],
      style: {
        color: "rgb(199, 146, 234)",
        fontFamily: 'OperatorMono-MediumItalic, OperatorMono-Medium, monospace',
      }
    }, {
      types: ["class-name"],
      style: {
        color: "rgb(255, 203, 139)"
      }
    }, {
      types: ["tag", "operator", "keyword"],
      style: {
        color: "rgb(127, 219, 202)",
        // fontFamily: 'OperatorMono-MediumItalic, OperatorMono-Medium, monospace',
      }
    }, {
      types: ["boolean"],
      style: {
        color: "rgb(255, 88, 116)"
      }
    }, {
      types: ["property"],
      style: {
        color: "rgb(128, 203, 196)"
      }
    }, {
      types: ["namespace"],
      style: {
        color: "rgb(178, 204, 214)"
      }
    }]
  };
  
  export default theme;
  