import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import Placeholder from "assets/images/placeholder.webp";
import PlaceholderDark from "assets/images/placeholder-dark.webp";
import { getImage } from "utils/image";

export default function Img(props: any) {
  const theme = useTheme();
  const [isLoaded, setLoaded] = React.useState(false);

  const imgStyles = {
    maxWidth: "100%",
    display: "block",
    border: "1px solid",
    borderColor:
      theme.palette.mode == "dark"
        ? "rgba(255,255,255,0.25)"
        : "rgba(0,0,0,0.15)",
    background:
      theme.palette.mode == "dark"
        ? "rgba(255,255,255,0.1)"
        : "rgba(0,0,0,0.1)",
    borderRadius: 10,
  };

  return (
    <Box
      sx={{
        my: 4,
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          maxWidth: "fit-content",
          textAlign: "center",
        }}
      >
        {!isLoaded && (
          <img
            src={theme.palette.mode === "dark" ? PlaceholderDark : Placeholder}
            title={props?.alt ?? props?.title}
            style={{
              ...imgStyles,
              width: "100%",
            }}
          />
        )}
        {props?.alt || props?.title ? (
          <>
            <img
              {...props}
              src={getImage(props.src)}
              title={props?.alt ?? props?.title}
              style={{
                ...imgStyles,
                maxWidth: "100%",
                display: !isLoaded ? "none" : null,
              }}
              onLoad={() => setLoaded(true)}
            />
            <Typography
              variant="caption"
              sx={{
                mt: 0,
                fontSize: 14,
                textAlign: "center",
                display: "block",
                color: "text.secondary",
              }}
            >
              {props?.alt ?? props?.title}
            </Typography>
          </>
        ) : (
          <>
            <img
              {...props}
              src={getImage(props.src)}
              style={{
                ...imgStyles,
                display: !isLoaded ? "none" : null,
              }}
              onLoad={() => setLoaded(true)}
            />
          </>
        )}
      </Box>
    </Box>
  );
}
