import * as React from "react";
import { styled, Theme } from "@mui/system";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { StaticImage } from "gatsby-plugin-image";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
interface PreHeaderProps {
  title: string;
  copied: boolean;
  onCopy: () => void;
  language: string;
}

const PreContainer = styled("div")(({ theme }: { theme: Theme }) => ({
  margin: 0,
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
  position: "relative",
}));

// const languageColors: { [key: string]: string } = {
//   css: "#4FC1E9",
//   js: "#F0DB4F",
//   jsx: "#61DBFB",
//   sass: "#CD6799",
//   scss: "#CD6799",
//   tsx: "#31786c",
//   ts: "#31786c",
// };

const PreHeader = ({ title, copied, onCopy, language }: PreHeaderProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return title ? (
    <Box
      sx={{
        textAlign: "left",
        p: "1em",
        fontSize: 14,
        color: "rgba(255,255,255,0.5)",
        borderRadius: "12px",
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0,
        border: "1px solid",
        borderColor: "divider",
        bgcolor: "rgb(1, 22, 38)",
        borderBottomColor: "rgba(255,255,255,0.2)",
      }}
    >
      <Grid container justifyContent="space-between" alignItems="center">
        <span style={{ marginBottom: isMobile ? 10 : 0 }}>
          {title?.replace(/_/g, " ")}
        </span>

        <Stack direction="row" spacing={1}>
          <button
            onClick={onCopy}
            className={
              copied ? "copy-button copy-button-active" : "copy-button"
            }
          >
            {copied ? "Copied!" : "Copy"}
          </button>

          <Icon language={language} />
        </Stack>
      </Grid>
    </Box>
  ) : (
    <div
      style={{
        position: "relative",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: "12px",
          right: "6px",
          border: "2px solid rgba(1, 22, 38, 1)",
          background: "rgba(1, 22, 38, 1)",
          padding: "0px 6px",
          borderRadius: "6px",
        }}
      >
        <Stack direction="row" spacing={1}>
          <button
            onClick={onCopy}
            className={
              copied ? "copy-button copy-button-active" : "copy-button"
            }
          >
            {copied ? "Copied!" : "Copy"}
          </button>

          <Icon language={language} />
        </Stack>
      </div>
    </div>
  );
};

const Icon = ({ language }: { language: string }) => {
  const iconProps: any = {
    placeholder: "blurred",
    alt: "icon",
    width: 26,
    objectFit: "contain",
  };

  return (
    <>
      {language === "rust" && (
        <StaticImage
          {...iconProps}
          src={`../../../assets/images/icons/rust.webp`}
        />
      )}

      {language === "kotlin" && (
        <StaticImage
          {...iconProps}
          src={`../../../assets/images/icons/kotlin.webp`}
        />
      )}

      {language === "swift" && (
        <StaticImage
          {...iconProps}
          src={`../../../assets/images/icons/swift.webp`}
        />
      )}

      {language === "dart" && (
        <StaticImage
          {...iconProps}
          src={`../../../assets/images/icons/dart.webp`}
        />
      )}

      {language === "jsx" && (
        <StaticImage
          {...iconProps}
          src={`../../../assets/images/icons/jsx.webp`}
        />
      )}

      {language === "js" && (
        <StaticImage
          {...iconProps}
          src={`../../../assets/images/icons/js.webp`}
        />
      )}

      {language === "html" && (
        <StaticImage
          {...iconProps}
          src={`../../../assets/images/icons/html.webp`}
        />
      )}

      {language === "css" && (
        <StaticImage
          {...iconProps}
          src={`../../../assets/images/icons/css.webp`}
        />
      )}
    </>
  );
};

const Pre = styled("pre")({
  borderRadius: "12px",
  marginTop: 0,
  marginBottom: 0,
  borderTopRightRadius: 0,
  borderTopLeftRadius: 0,
  textAlign: "left",
  padding: "1em",
  paddingLeft: "0.5em",
  overflow: "scroll",
  fontSize: 14,
  borderCollapse: "collapse",
});

const Line = styled("div")({
  display: "table-row",
});

const LineNo = styled("span")({
  display: "table-cell",
  minWidth: 30,
  textAlign: "right",
  paddingLeft: "0.5em",
  paddingRight: "1em",
  userSelect: "none",
  opacity: "0.5",
});

const LineContent = styled("span")({
  display: "table-cell",
  width: "100%",
});

export { PreContainer, PreHeader, Pre, Line, LineNo, LineContent };
