import React from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

function Icon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M5 5H24V7H5z" />
      <path d="M0 11H24V13H0z" />
      <path d="M12 17H24V19H12z" />
    </SvgIcon>
  );
}

export default Icon;
