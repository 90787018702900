import React from "react";
import { Box } from "@mui/material";
import { useTheme, alpha } from "@mui/material/styles";

interface Props {
  children: React.ReactNode;

  [x: string]: any;
}

export default function Blockquote({ children, ...rest }: Props) {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "block",
        color: theme.palette.primary.main,
        bgcolor: alpha(theme.palette.primary.main, 0.05),
        lineHeight: 1,
        fontSize: 16,
        p: 2,
        my: 4,
        border: "none",
        borderLeft: "4px solid",
        borderColor: theme.palette.primary.main,
      }}
      className="blockquote"
      {...rest}
    >
      {children}
    </Box>
  );
}
