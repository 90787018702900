import React from "react";
import ThemeTopLayout from "gatsby-theme-material-ui-top-layout/src/components/top-layout";
import Layout from "components/layout";
import { DefaultTheme } from "@mui/system";
import { lightTheme, darkTheme } from "../theme";

interface Props {
  children: React.ReactNode;
  theme?: DefaultTheme;
}

type ColorMode = "light" | "dark";

export const ColorModeContext = React.createContext({
  toggleColorMode: () => {},
});

export default function TopLayout({ children }: Props) {
  const [mode, setMode] = React.useState<ColorMode>("light");

  // set initial color mode
  React.useEffect(() => {
    if (typeof window !== "undefined") {
      setMode(
        (localStorage.getItem("colorMode") ??
          (window.matchMedia &&
          window.matchMedia("(prefers-color-scheme: dark)").matches
            ? "dark"
            : "light")) as ColorMode
      );
    }
  }, []);

  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => {
          const newMode = prevMode === "light" ? "dark" : "light";
          localStorage.setItem("colorMode", newMode);
          return newMode;
        });
      },
    }),
    []
  );

  const theme = React.useMemo(
    () => (mode === "dark" ? darkTheme : lightTheme),
    [mode]
  );

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeTopLayout theme={theme}>
        <div className={mode === "dark" ? "dark" : "light"}>
          <Layout>{children}</Layout>
        </div>
      </ThemeTopLayout>
    </ColorModeContext.Provider>
  );
}
