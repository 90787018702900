import * as React from "react";
import { Link } from "gatsby-theme-material-ui";

interface Props {
  label: string;
  to: string;
  isActive?: boolean;

  [x: string]: any;
}

export default function NavLink({
  label,
  to,
  isActive = false,
  ...rest
}: Props) {
  return (
    <Link
      textTransform={undefined}
      target={to.includes("http") ? "_blank" : "_self"}
      underline="none"
      color="default"
      to={to}
      sx={{
        display: "block",
        background:
          "linear-gradient(to left, #ffc700, #ffc700) no-repeat bottom left",
        pb: "5px",
        transition: "0.25s",
        textDecoration: "none !important",
        backgroundSize: isActive ? "75% 3px" : "0% 3px",
        color: isActive ? "text.primary" : "text.secondary",
        "&:hover": {
          backgroundSize: "75% 3px",
          color: "text.primary",
        },
      }}
      {...rest}
    >
      {label}
    </Link>
  );
}
