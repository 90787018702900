import React from "react";
import { useTheme } from "@mui/material/styles";

interface Props {
  children: React.ReactNode;

  [x: string]: any;
}

export default function InlineCode({ children, ...rest }: Props) {
  const theme = useTheme();

  return (
    <span
      style={{
        fontFamily: '"OperatorMono-Medium", "Courier New", Courier, monospace',
        fontWeight: "bold",
        color: theme.palette.primary.main,
      }}
      {...rest}
    >
      {children}
    </span>
  );
}
