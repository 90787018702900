import React from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

function Icon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M10.264 20.527c2.277 0 4.488-.763 6.282-2.166l5.64 5.64L24 22.187l-5.64-5.64a10.203 10.203 0 002.167-6.284C20.527 4.605 15.923 0 10.263 0 4.605 0 0 4.604 0 10.264c0 5.659 4.604 10.263 10.264 10.263zm0-17.961c4.245 0 7.697 3.452 7.697 7.698 0 4.245-3.452 7.697-7.697 7.697-4.246 0-7.698-3.452-7.698-7.697 0-4.246 3.452-7.698 7.698-7.698z"></path>
      <path d="M12.075 8.45c.486.487.754 1.131.754 1.814h2.566a5.102 5.102 0 00-1.506-3.629c-1.942-1.94-5.31-1.94-7.251 0l1.811 1.817c.976-.972 2.656-.97 3.626-.003z"></path>
    </SvgIcon>
  );
}

export default Icon;
