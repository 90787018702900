import React from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

function Icon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M12 14.4a2.4 2.4 0 100-4.801 2.4 2.4 0 000 4.801zm0 2.4a4.8 4.8 0 110-9.6 4.8 4.8 0 010 9.6zm7.2-6h3.6a1.2 1.2 0 110 2.4h-3.6a1.2 1.2 0 110-2.4zM12 18a1.2 1.2 0 011.2 1.2v3.6a1.2 1.2 0 11-2.4 0v-3.6A1.2 1.2 0 0112 18zm0-18a1.2 1.2 0 011.2 1.2v3.6a1.2 1.2 0 11-2.4 0V1.2A1.2 1.2 0 0112 0zM1.2 10.8h3.6a1.2 1.2 0 110 2.4H1.2a1.2 1.2 0 110-2.4zm16.74 5.443l2.545 2.544a1.2 1.2 0 01-1.697 1.698l-2.545-2.545a1.2 1.2 0 011.697-1.697zm-10.183 0a1.2 1.2 0 010 1.697l-2.544 2.545a1.2 1.2 0 01-1.698-1.697l2.545-2.545a1.2 1.2 0 011.697 0zM20.485 3.515a1.2 1.2 0 010 1.697L17.94 7.757a1.2 1.2 0 01-1.697-1.697l2.544-2.545a1.2 1.2 0 011.698 0zm-15.273 0L7.757 6.06A1.2 1.2 0 116.06 7.757L3.516 5.213a1.2 1.2 0 111.697-1.698h-.001z"></path>{" "}
    </SvgIcon>
  );
}

export default Icon;
