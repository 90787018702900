import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import AppBar from "./components/app-bar";
import Footer from "./components/footer";
import { MDXProvider } from "@mdx-js/react";
import { components } from "components/mdx";
import { useTheme } from "@mui/material/styles";
import { Helmet } from "react-helmet";

interface Props {
  window?: () => Window;
  children: React.ReactNode;
}

export default function Layout(props: Props) {
  const theme = useTheme();

  return (
    <React.Fragment>
      <Helmet
        htmlAttributes={{
          lang: "en",
        }}
        meta={[
          {
            property: "theme-color",
            content: theme.palette.background.default,
          },
          {
            property: "background-color",
            content: theme.palette.background.default,
          },
          {
            property: "msapplication-navbutton-color",
            content: theme.palette.background.default,
          },
          {
            property: "apple-mobile-web-app-status-bar-style",
            content: theme.palette.background.default,
          },
        ]}
      />

      <CssBaseline />
      <AppBar />
      <Toolbar />

      <MDXProvider components={components}>{props.children}</MDXProvider>
      <Footer />
    </React.Fragment>
  );
}

export function PageContainer(props: { children: React.ReactNode }) {
  return (
    <Container sx={{ py: 8, minHeight: "100vh" }}>{props.children}</Container>
  );
}
