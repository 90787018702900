import React from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

function Icon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M1.89864 22.1013C2.50108 22.7046 3.21679 23.1829 4.00464 23.5087C4.7925 23.8346 5.63698 24.0015 6.48956 24C7.34233 24.0015 8.18699 23.8345 8.97506 23.5087C9.76312 23.1828 10.4791 22.7046 11.0818 22.1013L14.7545 18.4272L12.9181 16.5909L9.24541 20.2649C8.51355 20.9935 7.5229 21.4025 6.49021 21.4025C5.45752 21.4025 4.46687 20.9935 3.73501 20.2649C3.00579 19.5334 2.59632 18.5426 2.59632 17.5097C2.59632 16.4768 3.00579 15.486 3.73501 14.7545L7.40904 11.0818L5.57268 9.24541L1.89864 12.9181C0.682805 14.1369 0 15.7882 0 17.5097C0 19.2313 0.682805 20.8825 1.89864 22.1013V22.1013ZM22.1013 11.0818C23.3165 9.86267 23.9989 8.21154 23.9989 6.49021C23.9989 4.76889 23.3165 3.11776 22.1013 1.89864C20.8825 0.682805 19.2313 0 17.5097 0C15.7882 0 14.1369 0.682805 12.9181 1.89864L9.24541 5.57268L11.0818 7.40905L14.7545 3.73501C15.4864 3.00643 16.477 2.59738 17.5097 2.59738C18.5424 2.59738 19.5331 3.00643 20.2649 3.73501C20.9941 4.46653 21.4036 5.45731 21.4036 6.49021C21.4036 7.52311 20.9941 8.51389 20.2649 9.24541L16.5909 12.9181L18.4272 14.7545L22.1013 11.0818Z" />
      <path d="M7.40778 18.4286L5.57011 16.5922L16.5922 5.57141L18.4286 7.40908L7.40778 18.4286Z" />
    </SvgIcon>
  );
}

export default Icon;
