import React from "react";
import { Typography, Divider, Box, Grid } from "@mui/material";
import CodeBlock from "components/mdx/code-block";
import InlineCode from "components/mdx/inline-code";
import Link from "components/mdx/link";
import Blockquote from "components/mdx/blockquote";
import Img from "components/mdx/img";
import LinkIcon from "assets/icons/link";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

function HeadingWrapper(props: { id: string; children: React.ReactNode }) {
  const [isHovered, setHovered] = React.useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid
      container
      alignItems="flex-start"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      style={{
        transform: "translateX(-28px)",
      }}
    >
      <Grid item>
        <a href={`#${props.id}`}>
          <LinkIcon
            color="primary"
            sx={{
              fontSize: 20,
              marginRight: 1,
              marginTop: 2.25,
              visibility: !isMobile && isHovered ? "visible" : "hidden",
              cursor: "pointer",
            }}
          />
        </a>
      </Grid>
      <Grid item xs>
        {props.children}
      </Grid>
    </Grid>
  );
}

export const components = {
  img: Img,
  hr: (props: any) => <Divider sx={{ my: 4 }} />,
  thematicBreak: (props: any) => <Divider sx={{ my: 4 }} />,
  code: CodeBlock,
  blockquote: Blockquote,
  inlineCode: (props: any) => <InlineCode {...props} />,
  a: (props: any) => <Link {...props} />,
  p: (props: any) => (
    <Typography
      variant="body1"
      sx={{
        fontSize: 16,
        lineHeight: 1.75,
        mt: 2,
        mb: 2,
        textAlign: "left",
      }}
      {...props}
    />
  ),
  em: (props: any) => (
    <span
      style={{
        // fontWeight: "bold",
        // fontStyle: "italic",
        wordSpacing: -5,
        fontFamily:
          '"OperatorMono-MediumItalic", "Muli", "Helvetica", "Arial", "sans-serif"',
      }}
      {...props}
    />
  ),
  ol: (props: any) => <ol {...props} style={{ paddingLeft: 20 }} />,
  ul: (props: any) => <ul {...props} style={{ paddingLeft: 20 }} />,
  li: (props: any) => (
    <li>
      <Typography
        variant="body1"
        sx={{
          fontSize: 16,
          lineHeight: 1.75,
          marginLeft: 0,
          marginTop: 1,
          marginBottom: 1,
        }}
        {...props}
      />
    </li>
  ),
  h1: (props: any) => (
    <HeadingWrapper id={props.id}>
      <Typography
        variant="h1"
        sx={{
          // offset anchor scroll
          mt: -10 + 2,
          pt: 10,

          lineHeight: 1,
          fontWeight: "bold",
          color: "primary.main",
          fontSize: "1.6em",
        }}
        {...props}
      />
    </HeadingWrapper>
  ),
  h2: (props: any) => (
    <HeadingWrapper id={props.id}>
      <Typography
        variant="h2"
        sx={{
          // offset anchor scroll
          mt: -10 + 2,
          pt: 10,

          lineHeight: 1,
          fontWeight: "bold",
          fontSize: "1.3em",
        }}
        {...props}
      />
    </HeadingWrapper>
  ),
  h3: (props: any) => (
    <HeadingWrapper id={props.id}>
      <Typography
        variant="h3"
        sx={{
          // offset anchor scroll
          mt: -10 + 2,
          pt: 10,

          lineHeight: 1,
          fontWeight: "bold",
          fontSize: "1em",
        }}
        {...props}
      />
    </HeadingWrapper>
  ),
};
