import React from "react";
import Highlight, { defaultProps, Language } from "prism-react-renderer";
import theme from "./theme";
import {
  Pre,
  Line,
  LineNo,
  LineContent,
  PreHeader,
  PreContainer,
} from "./components";
import "./style.css";
import Prism from "prism-react-renderer/prism";

((typeof global !== "undefined" ? global : window) as any).Prism = Prism;

require("prismjs/components/prism-kotlin");
require("prismjs/components/prism-swift");
require("prismjs/components/prism-dart");
require("prismjs/components/prism-rust");

let highlightStart = false;
let highlightNextLine = false;
const highlightClassName = "highlight-line";

const highlightLine = (lineArray: any, lineProps: any) => {
  let shouldExclude = false;
  const content = lineArray
    .map((line: any) => line.content)
    .join("")
    .replace(/\s/g, "");

  // console.log({ content, highlightNextLine });

  // stop highlight lines with "/*-highlight-line-*/"
  if (highlightNextLine) {
    lineProps.className = `${lineProps.className} ${highlightClassName}`;
    highlightNextLine = false;
  }

  // highlight lines with "/*-highlight-line-*/"
  if (content === "/*-highlight-line-*/") {
    shouldExclude = true;
    highlightNextLine = true;
  }

  // ----

  // Stop highlighting
  if (highlightStart && content.replace(/\s/g, "") === "/*-highlight-end-*/") {
    highlightStart = false;
    shouldExclude = true;
  }

  // Start highlighting after "/*-highlight-start-*/"
  if (content.replace(/\s/g, "") === "/*-highlight-start-*/") {
    highlightStart = true;
    shouldExclude = true;
  }

  // highlight lines between /*-highlight-start-*/ & /*-highlight-end-*/
  if (highlightStart) {
    lineProps.className = `${lineProps.className} ${highlightClassName}`;
  }

  return shouldExclude;
};

interface Props {
  children: string;
  className: string;
}

const getParams = (className = ``) => {
  const [language = ``, title = ``]: any = className.split(`:`);
  return { language: language?.replace(/language-/g, ""), title };
};

export default ({ children: codeString, className }: Props) => {
  const { language, title } = getParams(className);
  const [copied, setCopied] = React.useState(false);

  const handleCopy = (tokens: any) => {
    const text = tokens
      .slice(0, -1)
      .map((lineArrays: any) =>
        lineArrays.map((lineArray: any) => lineArray.content).join("")
      )
      .filter(
        (line: any) =>
          ![
            "/*-highlight-line-*/",
            "/*-highlight-start-*/",
            "/*-highlight-end-*/",
          ].includes(line.trim())
      )
      .join("\n");

    navigator.clipboard.writeText(text);
    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 1000);
  };

  return (
    <Highlight
      {...defaultProps}
      theme={theme}
      code={codeString}
      language={language as Language}
    >
      {({ className, style, tokens, getLineProps, getTokenProps }) => (
        <PreContainer>
          <PreHeader
            title={title}
            copied={copied}
            onCopy={() => handleCopy(tokens)}
            language={language as Language}
          />

          <Pre
            className={className}
            style={style}
            sx={{
              border: "1px solid",
              borderTop: title ? "none" : "1px solid",
              borderColor: "divider",
              borderTopLeftRadius: title ? 0 : "12px",
              borderTopRightRadius: title ? 0 : "12px",
            }}
          >
            {tokens.slice(0, -1).map((line: any, i) => {
              const lineProps = getLineProps({ line, key: i });
              const shouldExclude = highlightLine(line, lineProps);

              return !shouldExclude ? (
                <Line key={i} {...lineProps}>
                  <LineNo>{i + 1}</LineNo>
                  <LineContent
                    sx={{
                      paddingRight: title ? "0px" : "120px",
                    }}
                  >
                    {line.map((token: any, key: number) => (
                      <span key={key} {...getTokenProps({ token, key })} />
                    ))}
                  </LineContent>
                </Line>
              ) : null;
            })}
          </Pre>
        </PreContainer>
      )}
    </Highlight>
  );
};
