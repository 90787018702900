import React from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

function Icon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M9 .416a1.152 1.152 0 01.12 1.287 10.813 10.813 0 00-1.316 5.19c0 6.033 4.917 10.917 10.978 10.917.79 0 1.56-.082 2.3-.24a1.18 1.18 0 011.215.474 1.1 1.1 0 01-.047 1.34A12.525 12.525 0 0112.517 24C5.602 24.001 0 18.43 0 11.565 0 6.4 3.171 1.967 7.687.09A1.128 1.128 0 019 .416zm-1.712 1.55a10.905 10.905 0 00-5.75 9.6c0 6.03 4.919 10.914 10.98 10.914a10.974 10.974 0 007.807-3.243c-.505.063-1.02.094-1.543.094-6.916 0-12.516-5.571-12.516-12.436 0-1.75.363-3.417 1.022-4.93z"></path>
      <path d="M16.192 4.721a.326.326 0 01.619 0l.58 1.744c.26.777.869 1.386 1.646 1.645l1.743.58a.326.326 0 010 .619l-1.743.58a2.602 2.602 0 00-1.646 1.646l-.58 1.743a.326.326 0 01-.619 0l-.58-1.743a2.601 2.601 0 00-1.646-1.646l-1.743-.58a.325.325 0 010-.618l1.743-.58a2.6 2.6 0 001.646-1.646l.58-1.744zM20.796.147a.218.218 0 01.332-.105.218.218 0 01.08.105l.387 1.162c.172.519.579.925 1.098 1.098l1.16.387a.218.218 0 010 .41l-1.16.388a1.734 1.734 0 00-1.099 1.098l-.387 1.161a.218.218 0 01-.41 0l-.388-1.161a1.733 1.733 0 00-1.098-1.098l-1.16-.387a.218.218 0 010-.411l1.16-.387c.52-.173.926-.58 1.098-1.098l.387-1.16V.147z"></path>
    </SvgIcon>
  );
}

export default Icon;
